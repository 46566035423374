<template>
  <div class="card mb-4 border border-light card-dark dark">
    <div class="card-header pb-0">
      <h6>Comunidades </h6>
    </div>
    <div class="card-body px-0 pt-0 pb-2">
      <div class="table-responsive p-0">
        <table class="table align-items-center mb-0">
          <thead>
            <tr>
              <th
                class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7"
              >
                Comunidade
              </th>
              <th
                class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7 ps-2"
              >
                Pontos
              </th>
              <th
                class="text-center text-uppercase text-secondary text-xxs font-weight-bolder opacity-7"
              >
                Posi&ccedil;&atilde;o
              </th>
              <th
                class="text-center text-uppercase text-secondary text-xxs font-weight-bolder opacity-7"
              >
                Jogadores
              </th>
              <th class="text-secondary opacity-7"></th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="team in teams" :key="team.id">
              <td>
                <div class="d-flex px-2 py-1">
                  <div>
                    <soft-avatar
                      :img="images[team.id - 1]"
                      size="sm"
                      border-radius="lg"
                      class="me-3"
                      alt="user1"
                    />
                  </div>
                  <div class="d-flex flex-column justify-content-center">
                    <h6 class="mb-0 text-sm">{{ team.name }}</h6>
                  </div>
                </div>
              </td>
              <td>
                <p class="text-xs font-weight-bold mb-0">{{ team.score }}</p>
                <p class="text-xs text-secondary mb-0">Pontos</p>
              </td>
              <td class="align-middle text-center text-sm">
                <soft-badge :color="colors[team.position - 1]" variant="gradient" size="sm"
                  >{{ team.position }}</soft-badge
                >
              </td>
              <td class="align-middle text-center">
                <span class="text-secondary text-xs font-weight-bold"
                  >{{ team.players.length }} Jogador{{ team.players.length != 1 ? "es":"" }}</span
                >
              </td>
              <td class="align-middle">
                <router-link
                  :to="'/admin/players/' + team.id"
                  class="text-secondary font-weight-bold text-xs"
                  >Ver jogadores</router-link
                >
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</template>

<script>
import SoftAvatar from "@/components/SoftAvatar.vue";
import SoftBadge from "@/components/SoftBadge.vue";
import ana from "../../assets/img/team-1.jpg";
import ines from "../../assets/img/team-2.jpg";
import filipe from "../../assets/img/team-4.jpg";
import comboni from "../../assets/img/team-3.jpg";


export default {
    name: "authors-table",
    props: {
        teams: Array
    },
    data() {
        return {
            images: [
                ana, ines, comboni, filipe
            ],
            colors: [
                "success", "info", "warning", "danger"
            ]
        };
    },
    components: {
        SoftAvatar,
        SoftBadge,
    },
};
</script>

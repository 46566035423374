<template>
    <div class="p-0 m-0">
        <nav class="navbar navbar-expand-lg navbar-dark bg-dark col-12 justify-content-center shadow border-bottom border-light sticky-top px-2">
            <div class="row m-0 col-12 px-0">
                <div class="col-12 col-lg-auto d-flex d-lg-inline justify-content-between px-0 m-0">
                    <router-link class="navbar-brand text-light text-bold col-10 overflow-hidden px-0 mx-0" to="/">
                        <img :src="logo" alt="Logo" width="30" height="30">
                        Pastoral Juvenil Beata Anuarite - Chota
                    </router-link>
                    <button class="navbar-toggler" data-toggle="collapse" data-target="#navbarNav"
                        aria-controls="navbarNav" aria-expanded="false" aria-label="Toggle navigation">
                        <span class="fa fa-bars x2"></span>
                    </button>
                </div>
                <div class="collapse navbar-collapse col-auto" id="navbarNav">
                    <hr class="d-block d-lg-none">
                    <ul class="navbar-nav ml-auto">
                        <li class="nav-item">
                            <router-link class="nav-link px-2" to="/jogos">
                                <i class="fa fa-home pe-2"></i> 
                                Jogos
                            </router-link>
                        </li>
                        <li class="nav-item">
                            <router-link class="nav-link px-2" to="/comunidades">
                                <i class="fa fa-users pe-2"></i> 
                                Comunidades
                            </router-link>
                        </li>
                        <li class="nav-item">
                            <router-link class="nav-link px-2" to="/info">
                                <i class="fa fa-info pe-2"></i> 
                                Informa&ccedil;&otilde;es
                            </router-link>
                        </li>
                        <li class="nav-item">
                            <router-link class="nav-link px-2" to="/login">
                                <i class="fa fa-sign-in pe-2"></i> 
                                Entrar
                            </router-link>
                        </li>
                    </ul>
                </div>
            </div>
        </nav>

        <main class="main-content col-12 row m-0 p-1 pt-4 justify-content-center">
            <div class="col-12 col-md-10 col-lg-8 text-center mb-3 p-0 m-0">
                <router-view></router-view>
            </div>
            <div class="col-12 col-md-10 col-lg-4 p-4 pt-0">
                <div class="card card-light border shadow mb-3">
                    <div class="card-header h3">Beata Anuarite</div>
                    <div class="card-body">
                        <img src="/beata-anuarete.jpg" class="col-12">
                    </div>
                    <div class="card-footer">
                        Nossa padroeira
                    </div>
                </div>
                <div class="card card-light border shadow">
                    <div class="card-header h3">São Mateus evangelista</div>
                    <div class="card-body">
                        <img src="/sao-mateus.jpeg" class="col-12">
                    </div>
                    <div class="card-footer">
                        Padroeiro da par&oacute;quia
                    </div>
                </div>
            </div>
        </main>
    </div>
</template>

<script>
    import logo from '@/assets/img/logo-ct.png'
    export default {
        name: "LayoutView",
        data() {
            return {
                logo: logo
            }
        }
    }
</script>
<style scoped>
    .nav-link:hover {
        border-bottom: 2px solid goldenrod;
        color: goldenrod !important;
    }
</style>

import { createRouter, createWebHistory } from "vue-router";
// import Dashboard from "@/views/Dashboard.vue";
import Tables from "@/views/Tables.vue";
// import Billing from "@/views/Billing.vue";
// import VirtualReality from "@/views/VirtualReality.vue";
// import Profile from "@/views/Profile.vue";
// import Rtl from "@/views/Rtl.vue";
import SignIn from "@/views/SignIn.vue";
// import SignUp from "@/views/SignUp.vue";
import Players from "@/views/PlayersView.vue";
import Plays from "@/views/PlaysView.vue";
import Metrics from "@/views/MetricsView.vue";
import Play from "@/views/PlayView.vue";
import NewPlay from "@/views/NewPlayView.vue";
import PlaysView from "@/views/general/PlaysView.vue"
import ComunitiesView from "@/views/general/ComunitiesView.vue"
import Community from "@/views/general/Community.vue"
import PlayView from "@/views/general/PlayView.vue"
import InfoView from "@/views/general/InfoView.vue"
import HomeView from "@/views/general/HomeView.vue"
import View404 from "@/views/general/404.vue"

const routes = [
    {
        path: "/admin",
        name: "Dashboard",
        component: Tables,
        meta: {dashboard: true}
    },
    {
        path: "/admin/players/:id",
        name: "Jogadores",
        component: Players,
        meta: {dashboard: true}
    },
    {
        path: "/admin/metrics",
        name: "Metricas",
        component: Metrics,
        meta: {dashboard: true}
    },
    {
        path: "/admin/plays",
        name: "Jogos",
        component: Plays,
        meta: {dashboard: true}
    },
    {
        path: "/admin/plays/:id",
        name: "Jogo",
        component: Play,
        meta: {dashboard: true}
    },
    {
        path: "/admin/play",
        name: "Programar jogo",
        component: NewPlay,
        meta: {dashboard: true}
    },
    {
        path: "/login",
        name: "Sign In",
        component: SignIn,
        meta: {dashboard: true}
    },
    /**
     * Rotas gerais
     */
    {
        path: "/",
        name: "Inicio",
        redirect: "/home",
        children: [
            {
                path: '/home',
                name: 'Welcome',
                component: HomeView
            },
            {
                path: '/jogos',
                name: 'Todos Jogos',
                component: PlaysView
            },
            {
                path: '/jogos/:id',
                name: 'Ver Jogo',
                component: PlayView
            },
            {
                path: '/comunidades/',
                name: 'Comunidades',
                component: ComunitiesView
            },
            {
                path: '/comunidades/:id',
                name: 'Comunidade',
                component: Community
            },
            {
                path: '/info',
                name: 'Info',
                component: InfoView
            },
        ]
    },
    /*
    {
        path: "/billing",
        name: "Billing",
        component: Billing,
    },
    {
        path: "/virtual-reality",
        name: "Virtual Reality",
        component: VirtualReality,
    },
    {
        path: "/profile",
        name: "Profile",
        component: Profile,
    },
    {
        path: "/rtl-page",
        name: "Rtl",
        component: Rtl,
    },*/
    {
        path: '/:catchAll(.*)',
        component: View404, // ou qualquer outra página padrão que você deseje
    }
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
  linkActiveClass: "active",
});

export default router;

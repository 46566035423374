<template>
    <div class="" id="user-view" v-if="!dashboard">
        <layout></layout>
        <app-footer v-show="this.$store.state.showFooter" />
    </div>
    <div class="" id="admin-dashboard" v-else>
        <sidenav
            :custom_class="this.$store.state.mcolor"
            :class="[
            this.$store.state.isTransparent,
            this.$store.state.isRTL ? 'fixed-end' : 'fixed-start',
            ]"
            v-if="this.$store.state.showSidenav"
        />
        <main
            class="main-content position-relative max-height-vh-100 h-100 border-radius-lg bg-dark"
            :style="this.$store.state.isRTL ? 'overflow-x: hidden' : ''"
        >
            <navbar
                :class="[navClasses]"
                :textWhite="this.$store.state.isAbsolute ? 'text-white opacity-8' : ''"
                :minNav="navbarMinimize"
                v-if="this.$store.state.showNavbar"
            />
            <router-view />
            <app-footer v-show="this.$store.state.showFooter" />
            
        </main>
    </div>
</template>
<script>
    import Sidenav from "./examples/Sidenav/index.vue"
    import Navbar from "./examples/Navbars/Navbar.vue";
    import AppFooter from "./examples/Footer.vue";
    import { mapMutations } from "vuex";
    import Layout from "./views/general/Layout.vue";

    export default {
        name: "App",
        components: {
            Sidenav,
            Navbar,
            AppFooter,
            Layout,
        },
        data() {
            return {
                dashboard: false
            }
        },
        methods: {
            ...mapMutations(["toggleConfigurator", "navbarMinimize"]),
            updateLayout() {
                this.dashboard = this.$route.meta.dashboard
            }
        },
        computed: {
            navClasses() {
                return {
                    "position-sticky blur shadow-blur mt-4 left-auto top-1 z-index-sticky": this
                    .$store.state.isNavFixed,
                    "position-absolute px-4 mx-0 w-100 z-index-2": this.$store.state
                    .isAbsolute,
                    "px-0 mx-4 mt-4": !this.$store.state.isAbsolute,
                };
            },
        },
        watch: {
            '$route'() {
                this.updateLayout();
            }
        },
        beforeMount() {
            this.$store.state.isTransparent = "bg-dark";
        },
    };
</script>

<style scoped>
    main {
        background: transparent !important;
    }
</style>

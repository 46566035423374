<template>
    <div class="col-12">
        <loader v-if="loading"></loader>
        
        <div class="col-12 p-4 shadow bg-white card border" v-else>
            <div class="card-header h5">Pastoral juvenil da par&oacute;quia são Mateus apóstolo - Chota</div>
            <div class="col-12 card-body p-3 text-start">
                A pastorla juvenil da par&oacute;quia são Mateus apóstolo - Chota organiza um campeonato de futebol 
                entre os jovens das comunidades da paróquia. <br>
                Ser&atilde;o duas fases de uma renhida e amig&aacute;vel competi&ccedil;&atilde;o seguida de uma fase final 
                onde se determinar&atilde;o os melhores entre os melhores. Espera-se que esta competi&ccedil;&atilde;o seja para todos um momento  
                de comunh&atilde;o e ora&ccedil;&atilde;o entre os jovens.<br><br>

                Que a nossa padroeira, Beata Anuarite e o padroeiro da nossa par&oacute;quia, S&atilde; Mateus evangelista intercedam por n&oacute;s.
                <br>
                <div class="row p-2 justify-content-center align-items-center">
                    <hr class="col-10">
                    <div v-for="img in images" class="p-2 team col-6 col-md-4 col-lg-3" :key="img">
                        <img class="col-12" :src="img">
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    // import Api from '@/Api.js'
    import Loader from "@/components/Loader.vue"

    import ana from "@/assets/img/team-1.jpg";
    import ines from "@/assets/img/team-2.jpg";
    import filipe from "@/assets/img/team-4.jpg";
    import comboni from "@/assets/img/team-3.jpg";

    export default {
        name: "PlaysView",
        data() {
            return {
                loading: true,
                images: [
                    ana, ines, comboni, filipe
                ],
            }
        },
        components: {
            Loader,
        },
        async mounted() {
            this.loading = false
        },
        methods: {
            
        }
    }
</script>

<style scoped>
    .team > img {
        border-radius: 100%;
    }
</style>

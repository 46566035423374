<template>
  <footer class="py-3 text-center border-top">
        ©
        {{ new Date().getFullYear() }}, criado com 
        <i class="fa fa-heart"></i> por
        Pastoral Juvenil - chota
  </footer>
</template>

<script>
    export default {
    name: "app-footer",
    };
</script>

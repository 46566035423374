<template>
    <loader v-if="loading"></loader>
    <div class="py-4 container-fluid" v-else>
        <div class="card mb-4 border border-light card-dark dark">
            <div class="card-header pb-0">
                <h6>
                    {{ play.home.name }} VS {{ play.visitor.name }}&emsp; 
                    <soft-badge :color="stages[play.stage][1]" variant="gradient">
                        {{ stages[play.stage][0] }}
                    </soft-badge>
                </h6>
            </div>
            <div class="card-body px-0 pt-0 pb-2">
                <div class="form row p-3">
                    <div class="form-group col-12 col-md-6 col-xl-4">
                        <label class="form-control">
                            <span class="small py-0 m-0">{{ play.home.name }}:</span><br>
                            <h6>{{ play.home_gols }} Golos</h6>
                        </label>
                    </div>
                    <div class="form-group col-12 col-md-6 col-xl-4">
                        <label class="form-control">
                            <span class="small py-0 m-0">{{ play.visitor.name }}:</span><br>
                            <h6>{{ play.visitor_gols }} Golos</h6>
                        </label>
                    </div>
                    <div class="form-group col-12 col-md-6 col-xl-4">
                        <label class="form-control">
                            <span class="small py-0 m-0">Vencedor:</span><br>
                            <h6> {{ play.finished_at ? (play.home_gols > play.visitor_gols ? play.home.name : (play.home_gols == play.visitor_gols ? "Empate" : play.visitor.name)):"Em andamento" }} </h6>
                        </label>
                    </div>

                    <div class="form-group col-12 col-md-6 col-xl-4">
                        <label class="form-control">
                            <span class="small py-0 m-0">Árbitro:</span><br>
                            <h6> {{ play.judge }} </h6>
                        </label>
                    </div>
                    <div class="form-group col-12 col-md-6 col-xl-4">
                        <label class="form-control">
                            <span class="small py-0 m-0">Com&ccedil;ea/come&ccedil;ou:</span><br>
                            <h6> {{ (new Date(play.start_at)).toLocaleString() }} </h6>
                        </label>
                    </div>
                    <div class="form-group col-12 col-md-6 col-xl-4">
                        <label class="form-control">
                            <span class="small py-0 m-0">Terminou:</span><br>
                            <h6> {{ play.finished_at ? (new Date(play.finished_at)).toLocaleString() : "Não" }} </h6>
                        </label>
                    </div>
                </div>

                <div class="row p-3" v-if="!play.finished_at">
                    <a href="#add" class="form-group col-12 col-md-auto p-2">
                        <label>Adicionar</label>
                        <soft-button color="warning" variant="gradient" class="form-control">
                            Adicionar M&eacute;trica
                        </soft-button>
                    </a>
                    <form class="col-12 col-md-auto p-2 row" @submit.prevent="finalize">
                        <label class="pb-0 mb-0">Finalizar jogo</label>
                        <span class="col-12 col-md-auto py-3 py-md-0">
                            <input class="form-control" type="datetime-local" v-model="finished_at" name="finish" placeholder="Terminado em" required>
                        </span>
                        <span class="col-12 col-md-auto">
                            <soft-button color="success" variant="gradient" class="col-12 col-md-auto">
                                Finalizar
                            </soft-button>
                        </span>
                    </form>
                </div>

                <h6 class="pt-3 p-3">M&eacute;tricas do jogo</h6>
                <div class="table-responsive p-3">
                    <table class="table">
                        <thead class="table-header">
                            <th></th>
                            <th class="text-center">Jogador</th>
                            <th class="text-center">Equipa</th>
                            <th class="text-center">Momento</th>
                        </thead>
                        <tbody>
                            <tr v-for="metric in play.metrics" :key="metric.id">
                                <td class="text-center">{{ metric.metric.name }}</td>
                                <td class="text-center">{{ metric.player.name }}</td>
                                <td class="text-center">{{ metric.team.name }}</td>
                                <td class="text-center">{{ metric.mins }}min{{ metric.secs }}secs</td>
                            </tr>
                        </tbody>
                    </table>
                </div>

                <hr v-if="!play.finished_at">
                <h6 class="px-3" v-if="!play.finished_at">Adicionar m&eacute;trica ao jogo</h6>
                <form class="p-3 form-group row" @submit.prevent="sendMetric" v-if="!play.finished_at">
                    <div class="form-group col-12 col-md-6 col-lg-4">
                        <label>M&eacute;trica</label>
                        <select required class="form-control form-select" v-model="metric.metric_id" name="metric_id">
                            <option v-for="m in metrics" :key="m.id" :value="m.id">{{ m.name }}</option>
                        </select>
                    </div>
                    <div class="form-group col-12 col-md-6 col-lg-4">
                        <label>Comunidade</label>
                        <select required class="form-control form-select" v-model="metric.team_id" name="team_id">
                            <option :value="play.home.id">{{ play.home.name }}</option>
                            <option :value="play.visitor.id">{{ play.visitor.name }}</option>
                        </select>
                    </div>
                    <div class="form-group col-12 col-md-6 col-lg-4">
                        <label>Jogador</label>
                        <select required class="form-control form-select" v-model="metric.player_id" name="player_id">
                            <option v-for="player in players[metric.team_id]" :key="player.id" :value="player.id">{{ player.name }}</option>
                        </select>
                    </div>
                    <div class="form-group col-12 col-md-6 col-lg-4">
                        <label>Minutos</label>
                        <input class="form-control" required type="number" step="1" min="0" name="mins" v-model="metric.mins">
                    </div>
                    <div class="form-group col-12 col-md-6 col-lg-4">
                        <label>Segundos</label>
                        <input class="form-control" required type="number" step="1" min="0" max="60" name="secs" v-model="metric.secs">
                    </div>

                    <div class="form-group col-12 col-md-6 col-lg-4">
                        <label>Cadastrar</label>
                        <soft-button class="form-control" variant="gradient">
                            Cadastrar
                        </soft-button>
                    </div>
                </form>
            </div>
        </div>
    </div>
</template>

<script>
// import SoftAvatar from "@/components/SoftAvatar.vue";
import SoftBadge from "@/components/SoftBadge.vue";
import Loader from '@/components/Loader.vue';
import ana from "@/assets/img/team-1.jpg";
import ines from "@/assets/img/team-2.jpg";
import filipe from "@/assets/img/team-4.jpg";
import comboni from "@/assets/img/team-3.jpg";
import Api from '@/Api.js'
import SoftButton from '../components/SoftButton.vue';


export default {
    name: "authors-table",
    props: {
        teams: Array
    },
    data() {
        return {
            images: [
                ana, ines, filipe, comboni
            ],
            stages: {
                final1: ['final', 'success'],
                final2: ['final', 'success'],
                '1': ['primeira fase', 'warning'],
                '2': ['segunda fase', 'info']
            },
            play: null,
            loading: true,
            metric: {
                metric_id: null,
                team_id: null,
                player_id: null,
                secs: 0,
                mins: 0
            },
            metrics: [],
            players: {},
            finished_at: null
        };
    },
    components: {
        // SoftAvatar,
        SoftBadge,
        Loader,
        SoftButton,
    },
    async created() {
        this.getPlay()
        this.getMetrics()
    },
    methods: {
        async getPlay() {
            this.loading = true
            const play = await Api.get("/plays/" + this.$route.params.id) 

            if (play.status >= 200 && play.status < 300) {
                this.play = play.data.play
            } else {
                alert("Ocorreu um erro")
            }
            const home = await Api.get("/teams/" + this.play.home.id)
            this.players[this.play.home.id] = home.data.team.players
            const visitor = await Api.get("/teams/" + this.play.visitor.id)
            this.players[this.play.visitor.id] = visitor.data.team.players
            this.loading = false
        },
        async getMetrics() {
            this.loading = true

            const metrics = await Api.get("/admin/metrics")
            this.metrics = metrics.data.metrics

            this.loading = false
        },
        async sendMetric() {
            this.loading = true
            const res = await Api.post("/admin/metrics/play/" + this.play.id, this.metric)

            if (res.status >= 200 && res.status < 300) {
                alert("Métrica adicionada com sucesso")
                this.metric = {
                    metric_id: null,
                    team_id: null,
                    player_id: null,
                    secs: 0,
                    mins: 0
                }
            } else {
                alert("Ocorreu um erro")
                console.log(res)
            }
            this.getPlay()
            this.loading = false
        },
        async finalize() {
            this.loading = true
            const res = await Api.post("/admin/play/" + this.play.id, {'finished_at': this.finished_at})

            if (res.status >= 200 && res.status < 300) {
                alert("Jogo finalizado com sucesso")
                this.finished_at = null
            } else {
                alert("Ocorreu um erro")
                console.log(res)
            }
            this.getPlay()
            this.loading = false
        }
    }
};
</script>

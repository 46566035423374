<template>
    <loader v-if="loading"></loader>
    <div class="py-4 container-fluid" v-else>
        <div class="card mb-4 border border-light card-dark dark">
            <div class="card-header pb-0">
                Programar um novo jogo
            </div>
            <div class="card-body">
                <form class="row p-4" @submit.prevent="send">
                    <div class="form-group col-12 col-md-6 col-lg-4">
                        <label>Casa</label>
                        <select name="home_id" v-model="play.home_id" class="form-control form-select" @change="changeTeams">
                            <option v-for="team in homes" :value="team.id" :key="team.id">{{ team.name }}</option>
                        </select>
                    </div>

                    <div class="form-group col-12 col-md-6 col-lg-4">
                        <label>Visitante</label>
                        <select name="visitor_id" v-model="play.visitor_id" class="form-control form-select" @change="changeTeams">
                            <option v-for="team in outs" :value="team.id" :key="team.id">{{ team.name }}</option>
                        </select>
                    </div>

                    <div class="form-group col-12 col-md-6 col-lg-4">
                        <label>Árbitro</label>
                        <input type="text" name="judge" v-model="play.judge" class="form-control">
                    </div>
                    <div class="form-group col-12 col-md-6 col-lg-4">
                        <label>Data e hora</label>
                        <input type="datetime-local" name="start_at" v-model="play.start_at" class="form-control">
                    </div>

                    <div class="form-group col-12 col-md-6 col-lg-4">
                        <label>Feito</label>
                        <soft-button
                            variant="gradient"
                            color="success"
                            class="form-control"
                        >
                            Marcar jogo
                        </soft-button>
                    </div>
                </form>
            </div>
        </div>
    </div>
</template>

<script>
    import Loader from '@/components/Loader.vue'
    import Api from '@/Api.js'
    import SoftButton from '../components/SoftButton.vue'

    export default {
        name: 'new-play-view',
        components: {
            Loader,
            SoftButton
        },
        data() {
            return {
                loading: true,
                teams: [],
                outs: [],
                homes: [],
                play: {
                    home_id: 1,
                    visitor_id: 2,
                    judge: '',
                    start_at: new Date()
                }
            }
        },
        async created() {
            await this.getTeams();
            this.changeTeams()
        },
        methods: {
            async getTeams() {
                // confirm admin
                this.loading = true
                await Api.get("/admin/metrics");

                // get teams
                const teams = await Api.get("/teams")
                if (teams.status >= 200 && teams.status < 300) {
                    this.teams = teams.data.teams
                    this.outs = teams.data.teams
                    this.homes = teams.data.teams
                } else {
                    alert("Ocorreu um erro")
                }
                this.loading = false
            },
            changeTeams() {
                this.outs = this.teams.filter(team => team.id != this.play.home_id)
                this.homes = this.teams.filter(team => team.id != this.play.visitor_id)
            },
            async send() {
                this.loading = true

                const date = new Date(this.play.start_at);
                const year = date.getFullYear();
                const month = String(date.getMonth() + 1).padStart(2, '0');
                const day = String(date.getDate()).padStart(2, '0');
                const hour = String(date.getHours()).padStart(2, '0');
                const minute = String(date.getMinutes()).padStart(2, '0');
                const second = String(date.getSeconds()).padStart(2, '0');
                this.play.start_at = `${year}-${month}-${day} ${hour}:${minute}:${second}`;
                const res = await Api.post("/admin/play", this.play)
                if (res.status >= 200 && res.status < 300) {
                    alert("Jogo marcado com sucesso")
                    this.$router.push("/admin/plays/" + res.data.play.id)
                } else {
                    alert("Ocorreu um erro")
                }

                this.loading = false
            }
        }
    }
</script>

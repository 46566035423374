<template>
    <loader v-if="loading"></loader>
    <div class="py-4 container-fluid" v-else>
        <div class="card mb-4 border border-light card-dark dark">
            <div class="card-header pb-0">
                <h6>Jogos marcados </h6>
            </div>
            <div class="card-body px-0 pt-0 pb-2">
                <div class="table-responsive p-0">
                    <table class="table align-items-center mb-0">
                    <thead>
                        <tr>
                            <th>
                                Casa X Fora
                            </th>
                            <th class="text-center">Golos</th>
                            <th class="text-center">
                                Estado
                            </th>
                            <th class="text-center">
                                Data de jogo
                            </th>
                            <th class="text-center">
                                Fase
                            </th>
                            <th></th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr v-for="play in plays" :key="play.id">
                            <td>
                                <div class="d-flex px-2 py-1 justify-content-center">
                                    <div class="d-flex flex-column justify-content-center">
                                        <h6 class="mb-0 text-sm">{{ play.home.name }}</h6>
                                    </div>
                                    <div>
                                        <soft-avatar
                                            :img="images[play.home.id - 1]"
                                            size="sm"
                                            border-radius="lg"
                                            class="mx-3"
                                            alt="user1"
                                        />
                                    </div>
                                    <div class="d-flex flex-column justify-content-center">
                                        <h4 class="mb-0 text-sm">X</h4>
                                    </div>
                                    <div>
                                        <soft-avatar
                                            :img="images[play.visitor.id - 1]"
                                            size="sm"
                                            border-radius="lg"
                                            class="mx-3"
                                            alt="user1"
                                        />
                                    </div>
                                    <div class="d-flex flex-column justify-content-center">
                                        <h6 class="mb-0 text-sm">{{ play.visitor.name }}</h6>
                                    </div>
                                </div>
                            </td>
                            <td class="text-center">
                                {{ play.home_gols }} : {{ play.visitor_gols }}
                            </td>
                            <td class="text-center">
                                <p class="text-xs font-weight-bold mb-0">{{ play.finished_at ? "Terminado":"Pendente" }}</p>
                                <p class="text-xs text-secondary mb-0" v-if="play.finished_at">{{ (new Date(play.finished_at)).toLocaleDateString() }}</p>
                            </td>
                            <td class="align-middle text-center text-sm">
                                {{ (new Date(play.start_at)).toLocaleString() }}
                            </td>
                            <td class="align-middle text-center">
                                <span class="text-secondary text-xs font-weight-bold"
                                >{{ stages[play.stage] }}</span>
                            </td>
                            <td class="align-middle">
                                <router-link
                                :to="'/admin/plays/' + play.id"
                                class="text-secondary font-weight-bold text-xs"
                                >Ver mais</router-link>
                            </td>
                        </tr>
                    </tbody>
                    </table>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import SoftAvatar from "@/components/SoftAvatar.vue";
// import SoftBadge from "@/components/SoftBadge.vue";
import Loader from '@/components/Loader.vue';
import ana from "@/assets/img/team-1.jpg";
import ines from "@/assets/img/team-2.jpg";
import filipe from "@/assets/img/team-4.jpg";
import comboni from "@/assets/img/team-3.jpg";
import Api from '@/Api.js'


export default {
    name: "authors-table",
    props: {
        teams: Array
    },
    data() {
        return {
            images: [
                ana, ines, comboni, filipe
            ],
            stages: {
                final1: 'final',
                final2: 'final',
                '1': 'primeira fase',
                '2': 'segunda fase'
            },
            plays: [],
            loading: true
        };
    },
    components: {
        SoftAvatar,
        Loader
    },
    created() {
        this.getPlays()
    },
    methods: {
        async getPlays() {
            this.loading = true
            const plays = await Api.get("/plays") 

            if (plays.status >= 200 && plays.status < 300) {
                this.plays = plays.data.plays
            }
            this.loading = false
        } 
    }
};
</script>

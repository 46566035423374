<template>
    <div class="col-12 row m-0 p-0 justify-content-center">        
        <div class="col-11 col-sm-10 col-lg-8 p-1 p-lg-4">
            <div class="col-12">
                <img :src="logo" class="col-12" alt="liga beata anuarite">
            </div>
            <h3 class="h3 text-center col-12 py-2">
                Bem vindo a Liga Beata Anuarite
            </h3>
            <div class="text-center col-12">
                <router-link to="/jogos" class="btn btn-primary">Ver jogos</router-link>
            </div>
        </div>
    </div>
</template>

<script>
    import logo from "@/assets/img/logo-ct.png/"

    export default {
        name: "PlaysView",
        data() {
            return {
                logo
            }
        },
        components: {
        },
    }
</script>

<style scoped>
    .btn  {
        background: #aa712a !important;
        border: #e28a1d 1px solid !important;
    }
</style>

<template>
    <div class="col-12 p-0 m-0">
        <loader v-if="loading"></loader>
        
        <div class="col-12 p-0 m-0 shadow bg-white card border" v-else>
            <div>
                <div class="card-header bg-light">
                    <div class="row m-0 p-0">
                        <div class="col-12 text-start h5 p-2" v-if="plays.length <= 0">Ainda n&atilde;o h&aacute; jogos marcados para mostrar</div>
                        <div class="col-12 text-start h5 p-2" v-else>Lista de jogos</div>
                    </div>
                </div>
                <div class="p-0 col-auto">
                    <div @click="go(play.id)" v-for="play in plays" class="row jsutify-content-between col-12 play p-3 border-top border-light m-0 px-0" :key="play.id">
                        <div class="col-4 text-start">
                            <span class="badge bg-secondary bg-gradient p-1 small text-lowercase" v-if="play.finished_at">
                                FIM
                            </span>
                            <div class="blink d-inline-block bg-gradient bg-success" 
                                v-else-if="new Date(play.start_at) <= new Date()">
                            </div>
                            <span class="badge bg-warning bg-gradient p-1 small text-lowercase" v-else>
                                Pend
                            </span>
                        </div>
                        <div class="col-4 small text-muted text-center">{{ colors[play.stage].text }}</div>
                        <div class="d-none d-md-inli   ne col-4 small text-muted text-end">{{ (new Date(play.start_at).toLocaleString()) }}</div>
                        <div class="d-inlne d-md-none col-4 small text-muted text-end">{{ (new Date(play.start_at).toLocaleDateString()) }}</div>

                        <div class="col-5 text-end small px-0">
                            {{ play.home.name }} 
                            <img :src="images[play.home.id - 1]" class="team">
                        </div>
                        <div class="col-2 text-center match" :class="['text-' + (play.finished_at ? 'success':'warning')]"> 
                            <template v-if="!play.finished_at && (new Date(play.start_at) > new Date())">
                                --:--
                            </template>
                            <template v-else>
                                {{ play.home_gols }}-{{ play.visitor_gols }} 
                            </template>
                        </div>
                        <div class="col-5 text-start small px-0">
                            <img :src="images[play.visitor.id - 1]" class="team">
                            {{ play.visitor.name }} 
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import Api from '@/Api.js'
    import Loader from "@/components/Loader.vue"

    import ana from "@/assets/img/team-1.jpg";
    import ines from "@/assets/img/team-2.jpg";
    import filipe from "@/assets/img/team-4.jpg";
    import comboni from "@/assets/img/team-3.jpg";

    export default {
        name: "PlaysView",
        data() {
            return {
                plays: [],
                loading: false,
                images: [
                    ana, ines, comboni, filipe
                ],
                colors: {
                    '1': {color: 'success', text: '1 fase'},
                    '2': {color: 'warning', text: '2 fase'},
                    'final1': {color: 'success', text: 'final'},
                    'final2': {color: 'success', text: 'final'}
                },
            }
        },
        components: {
            Loader,
            // SoftBadge,
        },
        async created() {
            await this.getPlays()
        },
        methods: {
            async getPlays() {
                this.loading = true
                const plays = await Api.get("/plays")
                if (plays.status >= 200 && plays.status < 300) {
                    this.plays = plays.data.plays
                } else {
                    alert("Ocorreu um erro ao buscar os jogos")
                }

                this.loading = false
            },
            go(id) {
                this.$router.push("/jogos/" + id)
            }
        }
    }
</script>

<style scoped>
    @media screen and (max-width: 768px) {
        * {
            font-size: 14px !important;
        }
    }
    .row.play:hover {
        color: #6dbc06;
        cursor: pointer;
    }
    .team {
        width: 30px;
        height: 30px;
        border-radius: 50%;
    }
    .match {
        font-size: 1.1em;
    }
    .blink {
        width: 10px;
        height: 10px;
        border-radius: 50%;
        animation: blinking 1s infinite;
    }

    @keyframes blinking {
        0% { opacity: 1; }
        50% { opacity: 0; }
        100% { opacity: 1; }
    }
</style>

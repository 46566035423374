
class DateExtra extends window.Date {
    YEAR = Symbol()
    MONTH = Symbol()
    DAY = Symbol()

    static ToStr(number) {
        let str = `${number}`
        if (number < 10) {
            str = `0${number}`
        }
        return str
    }
    static dateToInt(d) {
        d = new Date(d)
        let str = `${d.getFullYear()}${DateExtra.ToStr(d.getMonth())}${DateExtra.ToStr(d.getDate())}`
        str += `${DateExtra.ToStr(d.getHours())}${DateExtra.ToStr(d.getMinutes())}${DateExtra.ToStr(d.getSeconds())}${d.getMilliseconds()}`

        return Number(str)
    }

    constructor(date = new Date()) {
        super(date)
    }


    // comparacoes
    static equal(d1, d2) {
        return DateExtra.dateToInt(d1) == DateExtra.dateToInt(d2)
    }
    static before(d1, d2) {
        return DateExtra.dateToInt(d1) < DateExtra.dateToInt(d2)
    }
    static beforeOrEqual(d1, d2) {
        return DateExtra.dateToInt(d1) <= DateExtra.dateToInt(d2)
    }
    static after(d1, d2) {
        return DateExtra.dateToInt(d1) > DateExtra.dateToInt(d2)
    }
    static afterOrEqual(d1, d2) {
        return DateExtra.dateToInt(d1) > DateExtra.dateToInt(d2)
    }
    equal(d) {
        return DateExtra.equal(this, d)
    }
    before(d) {
        return DateExtra.before(this, d)
    }
    beforeOrEqual(d) {
        return DateExtra.beforeOrEqual(this, d)
    }
    after(d) {
        return DateExtra.after(this, d)
    }
    afterOrEqual(d) {
        DateExtra.afterOrEqual(this, d)
    }

}

export default DateExtra

import axios from 'axios';

// const apiUrl = "http://192.168.43.88:8000/api"
// const apiUrl = "http://localhost:8000/api"
const apiUrl = "/api"
// const apiUrl = "https://pastoraljuvenil-chota.rf.gd/api"

const apiService = {
  getAuthHeader() {
    const token = sessionStorage.getItem("token")
    return {
      headers: {
        'Authorization': `Bearer ${token}`,
        'Content-Type': 'application/json',
      }
    };
  },

  async post(url, data) {
    const formData = new FormData();
    for (const key in data) {
      formData.append(key, data[key]);
    }
    try {
      const response = await axios.post(apiUrl + url, formData, this.getAuthHeader());
      return response;
    } catch (error) {
      return this.handleError(error);
    }
  },

  async get(url) {
    try {
      const response = await axios.get(apiUrl + url, this.getAuthHeader());
      return response;
    } catch (error) {
      return this.handleError(error);
    }
  },

  async put(url, data) {
    try {
      const response = await axios.put(apiUrl + url, data, this.getAuthHeader());
      return response;
    } catch (error) {
      return this.handleError(error);
    }
  },

  handleError(error) {
      console.error('API call error:', error);
    let  message = error.response.data.message
    if (error.response.status == 404) {
        message = "A pagina ou conteudo nao foi encontrado :\\"
    } else if (error.response.status == 401) {
        message = "Precisa iniciar sessao para aceder"
        location = "/login"
    } else if (error.response.status == 402) {
        message = "Conteudo ou pagina restrita"
        location = "/login"
    } else if (error.response.status == 400 || error.response.status == 422) {
        message = "Verifique os campos e preencha novamente"
    } else if (error.response.status == 403) {
        message = "Conteudo ou pagina restrita"
        location = "/login"
    }
    alert("Ocorreu um erro: \n" + message)
    return error.response
    // Adicione aqui qualquer tratamento de erro adicional, como exibir uma mensagem ao usuário
  }
}

export default apiService;

<template>
    <div class="col-12 p-1 m-0">
        <loader v-if="loading"></loader>
        
        <div class="col-12 p-0 shadow bg-white card border" v-else>
            <div>
                <div class="card-header bg-light">
                    <div class="row m-0 p-0">
                        <div class="col-12 text-start h5 p-2">Comunidades no campeonato</div>
                    </div>
                </div>
                <div class="">
                    <div @click="go(team.id)" v-for="team in teams" class="row jsutify-content-between col-12 play p-2 border-top border-light m-0" :key="team.id">
                        
                        <span class="small text-muted d-block col-auto px-0 m-0 col-12 text-start">
                            {{ team.score }} pontos
                        </span>
                        <div class="col-9 col-sm-6 text-start px-0">
                            <img :src="images[team.id - 1]" class="team p-1 border"
                                :class="['border-' + colors[team.position - 1]]"
                            >
                            <span class="d-none d-lg-inline"> comunidade </span>
                            {{ team.name }} 
                        </div>
                        <div class="col-3 text-center fw-bold p-0 match"> 
                            <span class="badge" v-if="team.position"
                                :class="['badge-' + colors[team.position - 1]]"
                            >{{ team.position }}<sup>o</sup> lugar</span>
                        </div>
                        <div class="col-3 text-start d-none d-sm-block px-0">
                            {{ team.players.length }} jogadores
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import Api from '@/Api.js'
    import Loader from "@/components/Loader.vue"

    import ana from "@/assets/img/team-1.jpg";
    import ines from "@/assets/img/team-2.jpg";
    import filipe from "@/assets/img/team-4.jpg";
    import comboni from "@/assets/img/team-3.jpg";

    export default {
        name: "PlaysView",
        data() {
            return {
                teams: [],
                loading: true,
                images: [
                    ana, ines, comboni, filipe
                ],
                colors: ['success', 'info', 'warning', 'danger']
            }
        },
        components: {
            Loader,
        },
        async created() {
            await this.getTeams()
        },
        methods: {
            async getTeams() {
                this.loading = true
                const teams = await Api.get("/teams")
                if (teams.status >= 200 && teams.status < 300) {
                    this.teams = teams.data.teams
                } else {
                    alert("Ocorreu um erro ao buscar os jogos")
                }

                this.loading = false
            },
            go(id) {
                this.$router.push("/comunidades/" + id)
            }
        }
    }
</script>

<style scoped>
    *:has(>*>.team):hover {
        color: #6dbc06 !important;
        cursor: pointer !important;
    }
    .team {
        width: 50px;
        height: 50px;
        border-radius: 50%;
        border-width: 2px !important;
    }
    .small {
        font-size: 0.75em !important;
    }
</style>

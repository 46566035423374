<template>
    <loader v-if="loadind"></loader>
    <div class="py-4 container-fluid " v-else>
        <div class="row">
            <div class="col-xl-3 col-sm-6 mb-xl-0 mb-4">
                <mini-statistics-card
                value="marcados"
                :title="plays.length + ' jogos'"
                :icon="{
                    component: 'fa fa-check',
                    background: 'bg-gradient-success',
                }"
                direction-reverse
                variation="dark"
            />
            </div>
            <div class="col-xl-3 col-sm-6 mb-xl-0 mb-4">
                <mini-statistics-card
                value="Restantes"
                :title="14 - plays.length + ' jogos'"
                :icon="{
                    component: ' ni ni-world',
                    background: 'bg-gradient-danger',
                }"
                direction-reverse
                />
            </div>
            <div class="col-xl-3 col-sm-6 mb-xl-0 mb-4">
                <mini-statistics-card
                    title="Fase actual"
                    :value="plays[0] ? stages[plays[0].stage]:'----'"
                    :icon="{
                        component: 'ni ni-paper-diploma',
                        background: 'bg-gradient-info',
                    }"
                    direction-reverse
                />
            </div>
            <div class="col-xl-3 col-sm-6 mb-xl-0">
                <mini-statistics-card
                    :value="teams[0].name"
                    title="1 lugar"
                    :icon="{
                        component: 'fa fa-medal',
                        background: 'bg-gradient-warning',
                    }"
                    direction-reverse
                />
            </div>
        </div>
        <div class="row">
            <div class="col-12">
                <authors-table :teams="teams" />
            </div>
        </div>
        <div class="row">
            <div class="col-12">
                <projects-table :metrics="metrics" />
            </div>
        </div>
    </div>
</template>

<script>
import AuthorsTable from "./components/AuthorsTable";
import ProjectsTable from "./components/ProjectsTable";
import Api from "@/Api.js"
import Loader from '@/components/Loader.vue';
import MiniStatisticsCard from "@/examples/Cards/MiniStatisticsCard.vue";

export default {
    name: "tablesView",
    data() {
        return {
            loadind: true,
            metrics: [],
            teams: [],
            plays: [],
            stages: {
                final1: 'final',
                final2: 'final',
                '1': 'primeira fase',
                '2': 'segunda fase'
            }
        }
    },
    components: {
        AuthorsTable,
        ProjectsTable,
        Loader,
        MiniStatisticsCard
    },
    async mounted() {
        this.loading = true

        const metrics = await Api.get("/admin/metrics");

        if (metrics.status >= 200 && metrics.status < 300) {
            this.metrics = metrics.data.metrics
        }

        const teams = await Api.get("/teams");
        const plays = await Api.get("/plays");

        this.teams = teams.data.teams
        this.plays = plays.data.plays
        console.log(this.plays)
        this.loadind = false
    }
};
</script>

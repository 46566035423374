<template>
    <div class="col-12 p-1 m-0">
        <loader v-if="loading"></loader>
        
        <div class="col-12 p-0 m-0 shadow bg-white card border" v-else>
            <div class="row p-0 m-0 justify-content-center">
                <div class="header py-3 m-0">
                    <div class="row m-0 p-2 px-0 px-md-2 align-items-center">
                        <div class="row m-0 p-2 align-items-center py-3 px-0">
                            <div class="col-4 d-none d-md-flex"></div>
                            <div class="text-start text-md-center col-6 col-md-4 small text-muted">
                                <span class="text-light text-white badge" :class="['bg-' + colors[play.stage].color]">
                                    {{ colors[play.stage].text }}
                                </span>
                            </div>
                            <div class="col-6 col-md-4 text-end text-muted small px-0">
                                Árbitro: {{ play.judge }}
                            </div>
                        </div>
                        <div class="col-5 text-end px-0">
                            <div class="col-auto text-center text-md-end px-0" @click="go(play.home.id)">
                                <span class="d-none d-md-inline px-0 col-md-4">{{ play.home.name }} </span>
                                <img :src="images[play.home.id - 1]" class="team ms-md-2 p-1 col-12 col-md-8"
                                    :class="{
                                        'border border-success': play.home_gols > play.visitor_gols,
                                        'border border-warning': play.home_gols == play.visitor_gols,
                                        'border border-danger': play.home_gols < play.visitor_gols,
                                        'blink2': play.home_gols < play.visitor_gols && !play.finished_at
                                    }"
                                >
                                <span class="br d-block d-md-none"></span>
                                <span class="d-inline d-md-none px-0">{{ play.home.name }} </span>
                            </div>
                        </div>
                        <div class="match fw-bold px-0 text-success col-2" v-if="new Date(play.start_at) > new Date() && !play.finished_at">
                            -- : --
                        </div>
                        <div class="match fw-bold px-0 text-success col-2" v-else>
                           {{ play.home_gols }}:{{ play.visitor_gols }}
                        </div>
                        <div class="col-5 text-start px-0 px-md-1">
                            <div class="col-auto text-center text-md-start px-0" @click="go(play.visitor.id)">
                                <img :src="images[play.visitor.id - 1]" class="team me-md-2 p-1 col-12 col-md-8"
                                    :class="{
                                        'border border-success': play.home_gols < play.visitor_gols,
                                        'border border-warning': play.home_gols == play.visitor_gols,
                                        'border border-danger': play.home_gols > play.visitor_gols,
                                        'blink2': play.home_gols > play.visitor_gols && !play.finished_at
                                    }"
                                >
                                <span class="br d-block d-md-none"></span>
                                <span class="px-0 col-md-4"> {{ play.visitor.name }}</span>
                            </div>
                        </div>
                        <div class="text-end col-12 py-2 small text-muted">
                            <i class="fa fa-calendar"></i> {{ (new Date(play.start_at)).toLocaleString() }}<br>
                            <span class="badge" :class="['bg-' + (play.finished_at ? 
                                'secondary':
                                (new Date(play.start_at) <= new Date() ? 'success blink':'warning')    
                            )]">
                                {{ play.finished_at ? 'Terminado':(new Date() >= new Date(play.start_at) ? 'Decorrendo':'pendente') }}
                            </span>
                        </div>
                    </div>
                </div>
                <div class="h5">Estat&iacute;sticas do jogo</div>
                <div class="row p-0 m-0 py-4 col-12 col-md-10 align-self-center justify-content-center">
                    <div class="row p-0 m-0 py-2 border-bottom col-12">
                        <div class="col-5 text-end px-0">{{ play.home.name }}</div>
                        <div class="col-2"></div>
                        <div class="col-5 text-start px-0">{{ play.visitor.name }}</div>
                    </div>

                    <!-- Fim do jogo -->
                    <div class="row px-0 p-1 m-0 border-bottom" v-if="play.finished_at">
                        <div class="col-5 text-end px-0"></div>
                        <div class="col-2 text-success fw-bold text-center px-0">
                            <span class="text-center small text-lowercase text-warning px-0">fim</span>
                        </div>
                        <div class="col-5 text-start"></div>
                    </div>
                    <!-- ./Fim do jogo -->

                    <div v-for="metric in play.metrics" :key="metric.id" class="row px-0 py-1 m-0 border-bottom">
                        <div class="col-5 text-end px-0">
                            <div class="col-12 text-end p-0" v-if="metric.team.id == play.home.id">
                                {{ metric.player.name }}
                                <span class="col-12 text-end small p-0 m-0 d-block" :class="{
                                    'text-warning': metric.metric.chave == 'yl',
                                    'text-danger': metric.metric.chave == 'vm',
                                    'text-success': metric.metric.chave == 'gol',
                                    'text-danger': metric.metric.chave == 'auto',
                                }">{{ metric.metric.name }}</span>
                            </div>
                        </div>

                        <div class="row p-1 m-0 align-items-center col-2 text-success fw-bold text-center justify-content-center">
                            <span class="col-auto bg-success bg-gradient mb-0 text-center text-white rounded border small">{{ metric.mins }}'</span>
                            <span class="small p-0 m-0 text-center col-12 text-success" v-if="metric.metric.chave == 'gol' || metric.metric.chave == 'auto'">
                                {{ gols[metric.id][0] }}-{{ gols[metric.id][1] }}
                            </span>
                        </div>

                        <div class="col-5 text-start px-0">
                            <div class="col-12 text-start p-0" v-if="metric.team.id == play.visitor.id">
                                {{ metric.player.name }}
                                <span class="col-12 text-start small p-0 m-0 d-block" :class="{
                                    'text-warning': metric.metric.chave == 'yl',
                                    'text-danger': metric.metric.chave == 'vm',
                                    'text-success': metric.metric.chave == 'gol',
                                    'text-danger': metric.metric.chave == 'auto',
                                }">{{ metric.metric.name }}</span>
                            </div>
                        </div>
                    </div>

                    <!-- Inicio do jogo -->
                    <div class="row px-0 py-1 m-0 border-bottom" v-if="new Date(play.start_at) <= new Date()">
                        <div class="col-5 text-end px-0"></div>
                        <div class="col-2 text-success px-0 fw-bold text-center">
                            <span class="text-center px-0 text-shadow text-lowercase">Início</span>
                        </div>
                        <div class="col-5 text-start"></div>
                    </div>
                    <!-- ./Inicio do jogo -->
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import Api from '@/Api.js'
    import Loader from "@/components/Loader.vue"
    // import SoftBadge from '@/components/SoftBadge.vue'
    import Date2 from "@/Date/index.js"

    import ana from "@/assets/img/team-1.jpg";
    import ines from "@/assets/img/team-2.jpg";
    import filipe from "@/assets/img/team-4.jpg";
    import comboni from "@/assets/img/team-3.jpg";

    export default {
        name: "PlaysView",
        data() {
            return {
                play: null,
                loading: false,
                images: [
                    ana, ines, comboni, filipe
                ],
                gols: {},
                colors: {
                    '1': {color: 'success', text: '1 fase'},
                    '2': {color: 'warning', text: '2 fase'},
                    'final1': {color: 'success', text: 'final'},
                    'final2': {color: 'success', text: 'final'}
                },
                Date2
            }
        },
        components: {
            Loader,
            // SoftBadge,
        },
        async created() {
            await this.getPlay()
        },
        methods: {
            async getPlay() {
                this.loading = true
                const plays = await Api.get("/plays/" + this.$route.params.id)
                if (plays.status >= 200 && plays.status < 300) {
                    this.play = plays.data.play
                    this.gols = {}
                    let home = 0
                    let visitor = 0
                    let reverse = [].concat(this.play.metrics)
                    reverse.reverse().forEach(metric => {
                        if (metric.metric.chave == 'gol') {
                            if (metric.team.id == this.play.home.id) {
                                home++;
                            } else {
                                visitor++;
                            }
                            this.gols[metric.id] = [home, visitor]
                        } else if (metric.metric.chave == 'auto') {
                            if (metric.team.id == this.play.home.id) {
                                visitor++;
                            } else {
                                home++;
                            }
                            this.gols[metric.id] = [home, visitor]
                        }
                    });
                } else {
                    alert("Ocorreu um erro ao buscar os dados do jogo")
                }

                this.loading = false
            },
            go(id) {
                this.$router.push("/comunidades/" + id)
            }
        }
    }
</script>

<style scoped>
    @media screen and (max-width: 768px) {
        * {
            font-size: 14px !important;
        }
    } 
    .row.play:hover {
        color: #6dbc06;
        cursor: pointer;
    }
    .team {
        border-radius: 50%;
        /* width: 7rem !important;
        height: 7rem !important; */
        border-width: 3px !important;
    }
    .blink {
        animation: blinking 2s infinite;
    }
    @keyframes blinking {
        0% { opacity: 1; }
        50% { opacity: 0; }
        100% { opacity: 1; }
    }
    *:has(>.team):hover {
        color: #6dbc06 !important;
        cursor: pointer !important;
    }
    .small {
        font-size: 0.85rem;
    }
</style>

<template>
  <div class="card mb-4 border border-light">
    <div class="card-header pb-0">
      <h6>M&eacute;tricas de jogo</h6>
    </div>
    <div class="card-body px-0 pt-0 pb-2">
      <div class="table-responsive p-0">
        <table class="table align-items-center justify-content-center mb-0">
          <thead>
            <tr>
              <th
                class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7"
              >
                M&eacute;trica
              </th>
              <th
                class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7 ps-2"
              >
                Identificador
              </th>
              <th
                class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7 ps-2"
              >
                Adicionada em
              </th>
              <th></th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="metric in metrics" :key="metric.id">
              <td>
                <div class="d-flex px-2">
                  <div class="my-auto">
                    <h6 class="mb-0 text-sm">{{ metric.name }}</h6>
                  </div>
                </div>
              </td>
              <td>
                <p class="text-sm font-weight-bold mb-0">{{ metric.chave }}</p>
              </td>
              <td>
                <span class="text-xs font-weight-bold">{{ (new Date(metric.created_at)).toDateString() }}</span>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</template>

<script>
    // import SoftProgress from "@/components/SoftProgress";

    export default {
        props: {
            metrics: Array
        },
        name: "projects-table",
        components: {
            // SoftProgress,
        },
    };
</script>

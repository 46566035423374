<template>
    <div class="col-12">        
        <div class="col-12 p-2 row p-0 m-0 justify-content-center">
            <div class="404 h2 text-center">
                P&aacute;gina n&atilde;o encontrada
            </div>
            <div class="col-11 col-sm-10 col-lg-8 p-1 p-lg-4">
                <div class="col-12">
                    <img :src="logo" class="col-12" alt="liga beata anuarite">
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import logo from "@/assets/img/logo-ct.png/"
export default {
    name: "NotFoundView",
    data() {
        return { logo }
    }
}
</script>

<template>
    <loader v-if="loading"></loader>
    <div class="py-4 container-fluid" v-else>
        <div class="card card-dark mb-4 border border-light p-4">
            <div class="card-header pb-0">
                <h6 class="text-success">
                    M&eacute;tricas de jogo
                    <a href="#add">
                        <soft-button class=""
                            color="secondary"
                        >
                            <i class='fa fa-plus'></i>
                        </soft-button>
                    </a>
                </h6>
            </div>
            <div class="card-body px-0 pt-0 pb-2">
                <div class="table-responsive p-0">
                    <table class="table align-items-center mb-0">
                    <thead>
                        <tr>
                        <th
                            class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7"
                        >
                            Nome da m&eacute;trica
                        </th>
                        <th
                            class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7 ps-2"
                        >
                            Identificador
                        </th>
                        <th
                            class="text-center text-uppercase text-secondary text-xxs font-weight-bolder opacity-7"
                        >
                            Cadastrado em
                        </th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr v-for="metric in metrics" :key="metric.id">
                            <td>
                                <div class="d-flex px-2 py-1">
                                    <div class="d-flex flex-column justify-content-center">
                                        <h6 class="mb-0 text-sm">{{ metric.name }}</h6>
                                    </div>
                                </div>
                            </td>
                            <td>
                                <p class="text-xs font-weight-bold mb-0">{{ metric.chave }}</p>
                            </td>
                            <td class="align-middle text-center">
                                <span class="text-secondary text-xs font-weight-bold"
                                >{{ (new Date(metric.created_at)).toDateString() }}</span
                                >
                            </td>
                        </tr>
                    </tbody>
                    </table>
                </div>
            </div>
        </div>

        <div class="card mb-4 border border-light p-4">
            <div class="card-header pb-0" id="add">
                <h6 class="text-success">
                    Adicionar m&eacute;trica de jogo
                </h6>
            </div>
            <div class="card-body pt-0 pb-2">
                <form @submit="send">
                    <div class="row">
                        <div class="form-group col-12 col-md-8">
                            <label>Nome da m&eacute;trica</label>
                            <input required class="form-control" v-model="metric.name" type="text" name="name">
                        </div>
                        <div class="form-group col-12 col-md-4">
                            <label>Identificador</label>
                            <input required class="form-control" v-model="metric.chave" type="text" name="chave">
                        </div>

                        <div class="form-group">
                            <soft-button
                                class="col-12 col-md-auto"
                                color="success"
                                variant="gradient"
                            >
                                Cadastrar
                            </soft-button>
                        </div>
                    </div>
                </form>
            </div>
        </div>
    </div>
</template>

<script>
import Loader from '@/components/Loader.vue';
import Api from '@/Api.js'
import SoftButton from '@/components/SoftButton.vue';

export default {
    name: "players-view",
    data() {
        return {
            team: null,
            loading: true,
            metric: {
                name: '',
                chave: '',
            },
            metrics: []
        }
    },
    
    components: {
        Loader,
        SoftButton
    },
    async beforeMount() {
        this.getData();
    },
    methods: {
        async getData() {
            this.loading = true

            const metrics = await Api.get("/admin/metrics")
            this.metrics = metrics.data.metrics

            this.loading = false
        },
        async send() {
            this.loading = true

            const res = await Api.post("/admin/metrics", this.metric);
            if (res.status >= 200 && res.status < 300) {
                this.getData()
            } else {
                this.loading = false 
                alert("Ocorreu um erro");
            }
        }
    }
};
</script>

<template>
    <loader v-if="loading"></loader>
    <div class="py-4 container-fluid" v-else>
        <div class="card mb-4 border border-light p-4">
            <div class="card-header pb-0">
                <h6 class="text-success">
                    Jogadores da comunidade {{ team.name }} 
                    <a href="#add">
                        <soft-button class=""
                            color="secondary"
                        >
                            <i class='fa fa-plus'></i>
                        </soft-button>
                    </a>
                </h6>
            </div>
            <div class="card-body px-0 pt-0 pb-2">
                <div class="table-responsive p-0">
                    <table class="table align-items-center mb-0">
                    <thead>
                        <tr>
                        <th
                            class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7"
                        >
                            Nome do jogador
                        </th>
                        <th
                            class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7 ps-2"
                        >
                            Idade
                        </th>
                        <th
                            class="text-center text-uppercase text-secondary text-xxs font-weight-bolder opacity-7"
                        >
                            Cadastrado em
                        </th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr v-for="player in team.players" :key="player.id">
                            <td>
                                <div class="d-flex px-2 py-1">
                                    <div class="d-flex flex-column justify-content-center">
                                        <h6 class="mb-0 text-sm">{{ player.name }}</h6>
                                    </div>
                                </div>
                            </td>
                            <td>
                                <p class="text-xs font-weight-bold mb-0">{{ player.age }}</p>
                                <p class="text-xs text-secondary mb-0">anos</p>
                            </td>
                            <td class="align-middle text-center">
                                <span class="text-secondary text-xs font-weight-bold"
                                >{{ (new Date(player.created_at)).toDateString() }}</span
                                >
                            </td>
                        </tr>
                    </tbody>
                    </table>
                </div>
            </div>
        </div>

        <div class="card mb-4 border border-light p-4">
            <div class="card-header pb-0" id="add">
                <h6 class="text-success">
                    Adicionar jogador
                </h6>
            </div>
            <div class="card-body pt-0 pb-2">
                <form @submit="send">
                    <div class="row">
                        <div class="form-group col-12 col-md-8">
                            <label>Nome do jogador</label>
                            <input required class="form-control" v-model="player.name" type="text" name="name">
                        </div>
                        <div class="form-group col-12 col-md-4">
                            <label>Idade</label>
                            <input required class="form-control" v-model="player.age" type="number" min="6" step="1" name="name">
                        </div>

                        <div class="form-group">
                            <soft-button
                                class="col-12 col-md-auto"
                                color="success"
                                variant="gradient"
                            >
                                Cadastrar
                            </soft-button>
                        </div>
                    </div>
                </form>
            </div>
        </div>
    </div>
</template>

<script>
import Loader from '@/components/Loader.vue';
import Api from '@/Api.js'
import SoftButton from '@/components/SoftButton.vue';

export default {
    name: "players-view",
    data() {
        return {
            team: null,
            loading: true,
            player: {
                name: '',
                age: 6,
                team_id: null
            }
        }
    },
    
    components: {
        Loader,
        SoftButton
    },
    async beforeMount() {
        this.getPlayers();
    },
    methods: {
        async getPlayers() {
            this.loading = true

            const team = await Api.get("/teams/" + this.$route.params.id)
            this.team = team.data.team
            this.player.team_id = this.team.id

            this.loading = false
        },
        async send() {
            this.loading = true

            const res = await Api.post("/admin/players", this.player);
            if (res.status >= 200 && res.status < 300) {
                this.getPlayers()
            } else {
                this.loading = false 
                alert("Ocorreu um erro");
            }
        }
    }
};
</script>

<template>
    <div class="m-loading position-fixed col-12">
        <vs-loader variant="dots" color="#98ec2d" size="30"></vs-loader>
    </div>
</template>

<script>
    import VsLoader from '@vuesimple/vs-loader';
    
    export default {
        name: "LoaderView",
        components: {
            VsLoader
        }
    }
</script>

<template>
    <div class="col-12 p-1 m-0">
        <loader v-if="loading"></loader>
        
        <div class="col-12 p-0 shadow bg-white card border m-0" v-else>
            <div class="row p-0 m-0 justify-content-center">
                <div class="header py-3">
                    <div class="row m-0 p-2 align-items-center justify-content-center">
                        <div class="m-0 p-2 align-items-center py-3 text-center">
                            <span class="small text-center" v-if="team.position > 0"
                                :class="['text-' + colors[team.position - 1]]"
                            >{{ team.position }}<sup class="small fw-bold">o</sup> lugar</span>
                        </div>
                        <div class="text-center">
                            <img :src="images[team.id - 1]"
                                class="team border col-12 col-sm-8 col-md-6 col-lg-5 p-1"
                                :class="['border-' + colors[team.position - 1]]"
                            >
                            <span class="text-center h5 d-block col-12 pt-3">comunidade {{ team.name }}</span>
                        </div>
                        <div class="text-center small text-muted">{{ team.score }} pontos acumulados</div>
                    </div>
                </div>
                <div class="h5 pt-4">Jogadores da comunidade</div>
                <div class="row p-0 m-0 pb-4 col-12 col-md-10 align-self-center justify-content-center">
                    <div v-for="player in team.players" :key="player.id" class="row p-1 m-0 border-bottom">
                        <div class="col-8 col-sm-9 text-start">
                            {{ player.name }}
                        </div>
                        
                        <div class="col-4 col-sm-3 text-end"> </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import Api from '@/Api.js'
    import Loader from "@/components/Loader.vue"
    // import SoftBadge from '@/components/SoftBadge.vue'

    import ana from "@/assets/img/team-1.jpg";
    import ines from "@/assets/img/team-2.jpg";
    import filipe from "@/assets/img/team-4.jpg";
    import comboni from "@/assets/img/team-3.jpg";

    export default {
        name: "PlaysView",
        data() {
            return {
                team: null,
                loading: true,
                images: [
                    ana, ines, comboni, filipe
                ],
                colors: ['success', 'info', 'warning', 'danger'],
            }
        },
        components: {
            Loader,
            // SoftBadge,
        },
        async created() {
            await this.getTeam()
        },
        methods: {
            async getTeam() {
                this.loading = true
                const team = await Api.get("/teams/" + this.$route.params.id)
                if (team.status >= 200 && team.status < 300) {
                    this.team = team.data.team
                } else {
                    alert("Ocorreu um erro ao buscar os dados do jogo")
                }

                this.loading = false
            },
        }
    }
</script>

<style scoped>
    .team {
        border-radius: 100%;
        border-width: 5px !important;
        height: auto;
    }
    .small {
        font-size: 0.75em !important;
        font-family: system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif !important;
    }
</style>
